import {get, postJ} from "@/request";
import {IBanner,  IBannerStatus} from "@/apis/marketingManage/banner/types";



/**
 * 列表 -分页
 * @param params 查询参数
 */
export const queryBannerListApi = () => get<IBanner[]>("/golf/banner/queryBannerList", {})


/**
 * 跳转内容列表
 * @param skipType 跳转类型: 1=商品 2=旅游 3=赛事 4=1v1课程 5=网红 6=视频课程 查询参数
 */
export const querySkipContentListApi = (skipType?:string) => get<any[]>("/golf/banner/querySkipContentList", {skipType})


/**
 * 添加
 */
export const createBannerApi = (data: IBanner) => postJ("/golf/banner/create", data)

/**
 * 修改
 */
export const modifyBannerApi = (data: IBanner) => postJ("/golf/banner/modify", data)

/**
 * 查询详情
 * @param id
 */
export const getBannerDetailApi = (id: string) => get<IBanner>("/golf/banner/detail/" + id)

/**
 * 删除
 * @param id
 */
export const getBannerRemoveApi = (id: string) => get("/golf/banner/remove/" + id)


/**
 * 切换状态
 * @param id,status
 */
export const modifyBannerStatusApi = (data: IBannerStatus) => postJ("/golf/banner/statusUpdate",data)
